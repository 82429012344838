// rememberno jQuery ;)

//import 'fslightbox/fslightbox.min';

import SmoothScroll from "smooth-scroll";
import LazyLoad from "vanilla-lazyload";

document.addEventListener("DOMContentLoaded", () => {

    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
    });

    var scroll = new SmoothScroll('a[href*="#"]');

    // read more open //
    var readMoreBtn = document.getElementsByClassName('read-on');

    for (var i = 0; i < readMoreBtn.length; i++) {
      readMoreBtn[i].addEventListener('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.parentNode.classList.add('js-opened');

      }, false);
    }

    //const myTimeout = setTimeout(revealReadOnLinks, 6000);
    //revealReadOnLinks();

    // open menu //
    var navTrigger = document.querySelector(".js-open-nav"),
        navigation = document.querySelector(".navigation"),
        navLinks = document.querySelectorAll('.js-nav-link');

    // toggle menu //
    bindEvent(navTrigger, "click", resetNavPanel, false);

    // On click of the navigation links
    for(let link of navLinks){
        bindEvent(link, 'click', () => {
            resetNavPanel();
        }, false);
    }

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {

        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    // click function //
    function bindEvent(el, eventName, eventHandler) {
        if( el == null ) return;
	    if (el.addEventListener){
	        el.addEventListener(eventName, eventHandler, false);
	    } else if (el.attachEvent){
	        el.attachEvent('on'+eventName, eventHandler);
	    }
	}

    function resetNavPanel() {
        navigation.classList.toggle("js-is-open");
        navTrigger.classList.toggle("is-active");
    }
});

/**
 * Shows the read on links when the parent wrapper is greater than 960px in height.

 */
// function revealReadOnLinks() {
//     // Minimum height before a "Read on" button is displayed
//     const minRevealHeight = 600;
//     const paragraphWrappers = document.getElementsByClassName('paragraph-wrapper');

//     for( let wrapper of paragraphWrappers ) {

//         console.log(wrapper.offsetHeight)
//         if( wrapper.offsetHeight < minRevealHeight ){
//             wrapper.classList.add('reveal');
//             //continue;
//         }
//         if( wrapper.offsetHeight > minRevealHeight ){
//             wrapper.querySelector('.read-on').classList.remove('hidden');
//             //continue;
//         }
//         //wrapper.querySelector('.read-on').classList.remove('hidden');
//     }
// }

